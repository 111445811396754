<template>
  <div class="user-profile-container">
    <section class="user-appointments">
      <profile-menu></profile-menu>
    </section>
  </div>
</template>

<script>

import ProfileMenu from '@/components/user/menu/ProfileMenu.vue';

export default {
  data() {
    return {
    }
  },
  components: {
    ProfileMenu,

  },
  methods: {
  },
  computed: {
    currentUser () {
      return this.$store.state.AppActiveUser
    }
  },
  created() {

  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto;
  .user-appointments {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 35px;
    .user-appointments-info {
      padding-right: 50px;

    }
    .user-menu {
      width: 30%;
    }
  }
}

</style>
